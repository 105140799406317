﻿header{  
    display: none;  
    background-color: #fff;
    border-bottom: solid 1px rgba(160, 160, 160, 0.3);
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10000;
    #logo{
      float: left;
      margin: 15px;
    }
    nav{
      ul{ 
        li{
          float: left;
          margin: 15px;
          padding-right: 15px; 
          border-right: solid 1px rgba(160, 160, 160, 0.3); 
        }
        li:last-child{
          border-right: solid 0px rgba(160, 160, 160, 0.3); 
        }
      }
    }
}