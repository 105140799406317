﻿
//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #222 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

$brand-primary:         #00ccff !default; // #337ab7
$brand-success:         #5cb85c !default;
$brand-info:            #5bc0de !default;
$brand-warning:         #f0ad4e !default;
$brand-danger:          #d9534f !default;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #fff !default;
//** Global text color on `<body>`.
$text-color:            #222 !default;

//** Global textual link color.
$link-color:            $brand-primary !default;
//** Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 15%) !default;
//** Link hover decoration.
$link-hover-decoration: underline !default;



$font-family-sans-serif:  "Segoe UI",sans-serif,Helvetica,Arial !default;
$font-family-serif:       Georgia, "Times New Roman", Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:        $font-family-sans-serif !default;

$font-size-base:          16px !default;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1:            floor(($font-size-base * 2.9)) !default; // ~46px
$font-size-h2:            floor(($font-size-base * 1.65)) !default; // ~26px
$font-size-h3:            ceil(($font-size-base * 1.35)) !default; // ~22px
$font-size-h4:            ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5:            $font-size-base !default;
$font-size-h6:            ceil(($font-size-base * 0.85)) !default; // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:    inherit !default;
$headings-font-weight:    100 !default;
$headings-line-height:    1.3 !default;
$headings-color:          inherit !default;







//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs:                  480px !default;
$screen-xs-min:              $screen-xs !default;
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet

$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         0px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet:             (720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            (940px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      (1140px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop !default;
