﻿#home-view{

    .profile{
        text-align: center;
        padding: 15px;

        h1{

            letter-spacing: -0.01em;


        }

        p{

            line-height: 46px;
        }
        
        .circle{
                height: 150px;
                width: 150px;
                border-radius: 100px;
                background: url('./../images/steffen-gottschalk.jpg') center center/cover no-repeat;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 15px;
        }
    }
}