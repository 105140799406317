﻿body {
  font-size: $font-size-base;
  font-family: $font-family-sans-serif;  
  color: $text-color;
  background-color: #f4f4f4;

  .content-wrapper, #header-wrapper, #footer-wrapper{
    margin: 0 auto;
    max-width: 1200px;
  }
   #page-wrapper{
     margin: 45px 15px;
   }   
}


a{
  text-decoration: none !important;
    color: $text-color;
}
 a:hover{
       color: $brand-primary !important;
 }
  a:active{
       color: $text-color;
  }
a:visited{
       color: $text-color;
   }

h1{ 
font-size: $font-size-h1;            
font-weight: 100;
}

h2{ 
font-size: $font-size-h2;            
font-weight: 100;
margin: 18px 0 12px 0;
}

h3{ 
font-size: $font-size-h3;            
font-weight: 100;
margin: 12px 0 6px 0;
}

p{
font-weight: 100;
line-height: 180%;
margin: 0px 0 6px 0;
}

