﻿footer{
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 10000;
  text-align: center;
  padding: 15px 0px;
  font-size: 12px;
  background-color: #f4f4f4;
  font-weight: 100;
}

@media (max-width: $screen-xs) {
  #footer-wrapper{
    width: 270px;  
  }
}